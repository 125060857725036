import React from 'react';

import { Input } from '../../../../Input';
import { Checkbox } from '../../../../Checkbox';
import { Button } from '../../../../Button';
import { Heading } from '../../Common';

import background from '../../../../../../static/images/depositions-background.png';
import * as S from './styles';

function NewsLetter() {
  return (
    <S.Wrapper>
      <S.Content>
        <S.Texts>
          <Heading>Fique por dentro do que há de novo no mundo tech!</Heading>
          <p>
            Receba nossos insights, conteúdos de qualidade e nossas novidades
            diretamente no seu e-mail.
          </p>
        </S.Texts>

        <S.Form name="NewsLetter">
          <Input
            id="email"
            name="email"
            type="email"
            label="Confirme, o seu e-mail"
            required
          />

          <Checkbox label="Autorizo o recebimento de e-mails." required />

          <Button type="submit">Assinar newsletter</Button>
        </S.Form>
      </S.Content>

      <S.Image src={background} alt="" />
    </S.Wrapper>
  );
}

export default NewsLetter;
