import React from 'react';
import * as S from './styles';

export type EmphasisTextProps = {
  text: React.ReactNode;
};

function EmphasisText({ text }: EmphasisTextProps) {
  return (
    <S.Wrapper>
      <S.Text>{text}</S.Text>
    </S.Wrapper>
  );
}

export default EmphasisText;
