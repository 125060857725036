import styled, { css } from 'styled-components';

import {
  Form,
  ContentWrapper,
  TitleWrapper,
  FormWrapper
} from '../../ContactForm/styles';
import { device } from '../../../../../styles/media';
import { Section } from '../../Common';

export const Wrapper = styled(Section)`
  ${({ theme }) => css`
    overflow: hidden;

    section,
    ${ContentWrapper} {
      padding: 0;
    }

    ${TitleWrapper} {
      margin: 2.4rem 0 0;
    }

    ${ContentWrapper} {
      max-width: 64rem;
      margin: 0 auto;
    }

    h2 {
      color: ${theme.colors.lightgray};
      font-size: ${theme.font.sizes.large};
      font-weight: 500;
    }

    h3 {
      font-size: 1.8rem;
    }

    p {
      padding-top: 3rem;
      font-size: ${theme.font.sizes.small};
      transform: none;
    }

    ${FormWrapper} {
      padding-top: 4rem;
    }

    @media (min-width: 480px) {
      p {
        padding: 0;
        margin: auto;
      }
    }

    @media (min-width: 520px) {
      p {
        font-size: ${theme.font.sizes.medium};
      }
    }

    @media ${device.tablet} {
      h2 {
        font-size: ${theme.heading.sizes.h5};
      }

      h3 {
        margin-top: 2.4rem;
        font-size: ${theme.font.sizes.xxmedium};
      }

      p {
        font-size: 1.8rem;
      }
    }

    @media ${device.laptop} {
      p {
        margin-bottom: 4rem;
      }

      ${ContentWrapper} {
        max-width: unset;
        margin: 0;
      }

      ${Form} {
        padding: 0 1.6rem;
      }

      h3 {
        margin: 0;
        font-size: ${theme.font.sizes.large};
      }

      ${FormWrapper} {
        padding-top: 2.4rem;
      }
    }

    @media ${device.laptopL} {
      max-width: unset;

      section {
        max-width: 180rem;
        margin: 0 auto;
      }

      h2 {
        max-width: 116rem;
        font-size: ${theme.heading.sizes.h4};
      }

      h3 {
        font-size: ${theme.font.sizes.xlarge};
      }

      ${FormWrapper} {
        padding-top: 4rem;
      }

      p {
        margin-bottom: 6.8rem;
        max-width: 120rem;
      }
    }
  `}
`;
