import styled, { css } from 'styled-components';
import { device } from '../../../../../styles/media';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  max-width: 180rem;
  padding: 8rem 2rem;
  position: relative;
  min-height: 64vh;
  width: 100%;
  gap: 1.6rem;
  z-index: 2;

  a {
    margin-top: 2rem;
  }
`;

export const Heading = styled.h1`
  font-size: 4rem;
  max-width: 66rem;
  line-height: 1.1;
`;

export const Text = styled.p`
  line-height: 1.25;
  font-size: 1.8rem;
  max-width: 60rem;
`;

export const Cover = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
`;

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    position: relative;

    @media ${device.tablet} {
      ${Main} {
        a {
          margin-top: 2.4rem;
        }
      }

      ${Heading} {
        font-size: ${theme.font.sizes.xxlarge};
      }

      ${Text} {
        font-size: ${theme.font.sizes.xmedium};
        max-width: 70rem;
      }
    }

    @media ${device.laptop} {
      ${Main} {
        gap: 2.4rem;
        padding: 6rem max(2.4rem, 8%);
        min-height: 66rem;
      }

      ${Heading} {
        font-size: 5.6rem;
        max-width: 74rem;
      }

      ${Text} {
        max-width: 78rem;
        font-size: ${theme.font.sizes.xxmedium};
      }
    }

    @media ${device.laptopL} {
      ${Main} {
        gap: 3.2rem;
      }

      ${Heading} {
        max-width: 80rem;
        font-size: 6.2rem;
      }

      ${Text} {
        font-size: 2.6rem;
      }
    }
  `}
`;
