import React from 'react';
import { HeadFC } from 'gatsby';

import Seo from '../../components/Seo';
import TechRecruiter from '../../components/pages/Solutions/TechRecruiter';

function SolutionsPage() {
  return <TechRecruiter />;
}

export const Head: HeadFC = () => (
  <Seo title="Tech Recruiter" canonical="/solucoes/tech-recruiter" />
);

export default SolutionsPage;
