import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import ClientLogo from '../../../Home/Clients/ClientLogo';
import * as S from './styles';

function Clients() {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.UpperHeading>
        {intl.formatMessage({ id: 'our-clients-title' })}
      </S.UpperHeading>
      <S.Heading>
        {intl.formatMessage({ id: 'our-clients-text-1' })}
        <span>{intl.formatMessage({ id: 'our-clients-text-2' })}</span>
        {intl.formatMessage({ id: 'our-clients-text-3' })}
      </S.Heading>
      <ClientLogo />
    </S.Wrapper>
  );
}

export default Clients;
