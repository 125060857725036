import React from 'react';

import Layout from '../../../Layout';
import Product from '../../shared/Product';
import { Solutions } from '../../../Icons/Values/solutions';
import Posts from '../../../Posts';

import Cover from '../../../../../static/solutions/techrecruiter.png';

function TechRecruiter() {
  return (
    <Layout>
      <Product.Main
        cover={Cover}
        heading="Tech Recruiter"
        link="#contato"
        text={
          <>
            Recrutamento personalizado para atender às demandas únicas da sua
            empresa. Conte com a nossa{' '}
            <span className="semi-bold italic">experiência comprovada</span> em{' '}
            <span className="semi-bold italic">times ágeis</span> para encontrar
            os melhores profissionais de tecnologia.
          </>
        }
      />

      <Product.TextSection
        heading="Recrutamento de profissionais para todas as fases do desenvolvimento de soluções tecnológicas"
        texts={[
          'Ao longo dos anos, a Exactaworks se estabeleceu como uma referência na solução de alocação de times ágeis, com profissionais altamente qualificados e com máxima produtividade. ',
          'O serviço de tech recruiter traz toda essa inteligência institucional para identificar, atrair e selecionar talentos técnicos pontuais altamente qualificados para empresas que desejam efetivar internamente profissionais de tecnologia, como CLT ou PJ. '
        ]}
      />

      <Product.List
        heading={
          <>
            Seleção de <span className="semi-bold italic">talentos tech</span>{' '}
            feita por quem entende do assunto
          </>
        }
        text="Conte com a expertise da Exactaworks em montar squad as a service e contrate profissionais pontuais para diferentes áreas de tecnologia na sua empresa. "
        items={[
          {
            heading: 'Recrutadores altamente capacitados ',
            text: 'Nossas equipes têm experiência na contratação de profissionais de excelência e conta com uma extensa rede de talentos qualificados.'
          },
          {
            heading: 'Tech Recruiter accelerated by AI',
            text: 'Utilizamos ferramentas de Inteligência Artificial para otimizar a busca pelos melhores perfis de profissionais e desenvolver dossiês profundos de cada pessoa entrevistada.'
          },
          {
            heading: 'Processo de seleção rigoroso',
            text: 'Utilizamos metodologia ágil e técnicas específicas para uma seleção de talentos tech muito mais precisa, além de entrevistas técnicas detalhadas, testes práticos e avaliações de habilidades específicas.'
          },

          {
            heading: 'Abordagem proativa',
            text: 'Nossos tech recruiters identificam e contatam ativamente talentos técnicos, medem KPIs e tomam decisões orientadas por dados.'
          }
        ]}
      />

      <Product.EmphasisText
        text={
          <>
            <span className="semi-bold italic">Reduza o turnover</span> e{' '}
            <span className="semi-bold italic">
              aumente a retenção de talentos
            </span>{' '}
            com uma seleção de candidatos especializada e acelerada por
            ferramentas de inteligência artificial.
          </>
        }
      />

      <Product.TriggerAction
        text="Conte com a Exactaworks para realizar o recrutamento e seleção de tecnologia na sua empresa:"
        action={{
          label: 'Fale com a gente',
          href: '#contato'
        }}
      />

      <Product.Benefits
        heading={
          <>
            Por que contratar o{' '}
            <span className="emphasis color--green">Tech Recruiter</span> da
            Exactaworks?
          </>
        }
        text="Conheça as vantagens de contar com nossos Tech Recruiters na captação de profissionais para as diferentes ocupações e especialidades existentes  no seu negócio:"
        items={[
          {
            icon: <Solutions />,
            heading: 'Economia de recursos',
            text: 'Reduza tempo e custos investidos em longos processos de recrutamento e seleção.'
          },
          {
            icon: <Solutions />,
            heading: 'Amplo pool de talentos qualificados',
            text: 'Não sabe onde encontrar os melhores profissionais? A Exactaworks faz isso e seleciona pessoas ideais para o seu processo de contratação.'
          },
          {
            icon: <Solutions />,
            heading: 'Seleção de profissionais de todo o universo tech',
            text: 'Tech Leads, CTOs, Agilistas, Scrum Masters, Product Manager, Product Owner, Front-end, Back-end, UI e UX Designers, Devs, e muito mais!'
          },
          {
            icon: <Solutions />,
            heading: 'Acerte na escolha do seu time',
            text: 'Contrate profissionais com fit cultural e técnico, de acordo com as necessidades e características do seu negócio.'
          }
        ]}
      />

      {/* <Product.CardSlider
        heading="Cases de sucesso"
        cards={[
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 28, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 27, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 26, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 25, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 24, 2024',
            image: Cover
          }
        ]}
      /> */}

      <Product.SloganBenefits />

      <Product.BigNumbers
        items={[
          {
            label: 'mais rápido em tarefas repetitivas',
            value: '96%'
          },
          {
            label: 'maior confiança em qualidade de código',
            value: '85%'
          },
          {
            label: 'maior agilidade em code reviews',
            value: '15%'
          },
          {
            label: 'maior velocidade de maneira geral',
            value: '55%'
          }
        ]}
      />

      <Product.ExactaFlowTrigger />

      <Product.TriggerAction
        text={
          <>
            Vamos acelerar?
            <br /> o processo de <span className="emphasis">
              recrutamento
            </span>{' '}
            e <span className="emphasis">seleção</span> do setor de tecnologia
            do seu negócio?
          </>
        }
        action={{
          label: 'Fale com a gente',
          href: '#contato'
        }}
      />

      <Product.Clients />
      <Product.Contact
        title={
          <>
            {' '}
            Está em busca de um{' '}
            <span className="color--pink semi-bold italic">
              parceiro ideal
            </span>{' '}
            para{' '}
            <span className="color--green semi-bold italic">
              recrutamentos de tecnologia
            </span>{' '}
            efetivos??
          </>
        }
      />

      <Posts tag="tech-recruiter" />

      <Product.Newsletter />
    </Layout>
  );
}

export default TechRecruiter;
