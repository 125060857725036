import React from 'react';

import Link from '../../../../Link';

import * as S from './styles';

export type MainProps = {
  heading: string;
  text: React.ReactNode;
  newTab?: boolean;
  cover: string;
  link?: string;
};

function Main({ heading, newTab, cover, text, link }: MainProps) {
  return (
    <S.Wrapper>
      <S.Main>
        <S.Heading>{heading}</S.Heading>
        <S.Text>{text}</S.Text>
        <Link
          to={link!}
          target={newTab ? '_blank' : undefined}
          rel="nofollow noopener noreferrer"
        >
          Saiba mais
        </Link>
      </S.Main>
      <S.Cover src={cover} alt="" />
    </S.Wrapper>
  );
}

export default Main;
