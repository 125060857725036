import styled, { css } from 'styled-components';

import { device } from '../../../../../styles/media';
import { Section, Heading } from '../../Common';

export const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 58rem;
  width: 100%;
  gap: 2.4rem;

  button {
    margin: 1.6rem auto 0;
    max-width: 40rem;
    width: 100%;
  }
`;

export const Texts = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  max-width: 58rem;
  width: 100%;
  gap: 0.8rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 160rem;
  gap: 3.2rem;
  z-index: 1;
`;

export const Image = styled.img`
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  width: 50%;
`;

export const Wrapper = styled(Section)`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-bottom: 6rem;
    padding-top: 6rem;
    max-width: unset;

    @media ${device.tablet} {
      padding-bottom: 8rem;
      padding-top: 8rem;

      ${Content} {
        gap: 4rem;
      }
    }

    @media ${device.laptop} {
      padding-top: 10rem;
      padding-bottom: 10rem;

      ${Heading} {
        font-size: ${theme.heading.sizes.h5};
      }

      ${Content} {
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: row;
      }

      ${Image} {
        width: 80%;
        height: 90%;
      }

      ${Form} {
        label {
          width: 100%;
        }

        button {
          max-width: 20rem;
          margin: 1.6rem 0 0;
        }
      }

      ${Texts} {
        max-width: 60rem;
      }

      ${Form} {
        max-width: 42rem;
      }
    }
  `}
`;
