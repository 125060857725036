import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../styles/global';
import Theme from '../../styles/theme';

import Footer from './Footer';
import { ButtonColor } from '../Button';
import { Header } from './Header';
import * as S from './styles';

type LayoutProps = {
  children: React.ReactNode;
  color?: ButtonColor;
};

function Layout({ children, color = 'green' }: LayoutProps) {
  const [open, setOpen] = useState(false);

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />

      <S.Wrapper>
        <Header open={open} setOpen={setOpen} color={color} />
        <S.Content>{children}</S.Content>
        <Footer color={color} />
      </S.Wrapper>
    </ThemeProvider>
  );
}

export default Layout;
