import React from 'react';

import { Heading, Text } from '../../Common';
import { Conections } from '../../../../Icons/Values/conections';
import { Support } from '../../../../Icons/Values/suport';
import { People } from '../../../../Icons/Values/people';
import { Idea } from '../../../../Icons/Values/idea';
import { Teamwork } from '../../../../Icons/Values/teamwork';

import * as S from './styles';

const items = [
  {
    icon: <Conections />,
    heading: 'Nunc efficitur',
    text: 'Integer volutpat, purus et malesuada egestas, enim arcu dapibus libero, ut fringilla diam sem sit amet.'
  },
  {
    icon: <Support />,
    heading: 'Phasellus lobortis.',
    text: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia.'
  },
  {
    icon: <People />,
    heading: 'Suspendisse quis magna.',
    text: 'In vel tellus luctus, sollicitudin magna sit amet, placerat arcu. Mauris imperdiet felis eu ante vestibulum vestibulum. Aenean et dolor.'
  },
  {
    icon: <Idea />,
    heading: 'Nunc efficitur',
    text: 'Integer volutpat, purus et malesuada egestas, enim arcu dapibus libero, ut fringilla diam sem sit amet.'
  },
  {
    icon: <Teamwork />,
    heading: 'Phasellus lobortis.',
    text: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia.'
  }
];

function Methodology() {
  return (
    <S.Wrapper>
      <Heading>
        Como a <span className="semi-bold italic color--pink">Exactaworks</span>{' '}
        entrega os{' '}
        <span className="semi-bold italic color--pink">
          melhores resultados
        </span>{' '}
        para as empresas:
      </Heading>
      <Text>
        Nam quis turpis neque. Nulla varius, tortor vel ultrices condimentum,
        libero ex pharetra est, non accumsan est nulla quis enim. Donec ornare
        nisi non dui finibus, eu semper eros efficitur.
      </Text>

      {items?.length && (
        <S.List>
          {items.map((item, index) => (
            <S.Item key={index} right={index % 2 === 0}>
              <S.Icon>{item.icon}</S.Icon>
              <S.Content>
                <h3>{item.heading}</h3>
                <p>{item.text}</p>
              </S.Content>
            </S.Item>
          ))}
        </S.List>
      )}
    </S.Wrapper>
  );
}

export default Methodology;
