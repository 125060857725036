import styled, { css } from 'styled-components';
import { device } from '../../../../../styles/media';
import { Section, Heading } from '../../Common';

type ListItemProps = {
  right: boolean;
};

const modifiers = {
  right: () => css`
    text-align: right;

    ${Icon} {
      order: 1;
    }

    &::before {
      order: 2;
    }
  `
};

export const Item = styled.li<ListItemProps>`
  ${({ theme, right }) => css`
    display: flex;
    align-items: center;
    gap: 1.6rem;

    h3 {
      line-height: 1.3;
      font-size: 1.8rem;
    }

    p {
      line-height: 1.3;
      font-size: ${theme.font.sizes.small};
    }

    ${right && modifiers.right()}
  `}
`;

export const Content = styled.div`
  max-width: 32rem;
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.pink};
    border: 2px dashed ${theme.colors.pink};
    border-radius: 50%;
    height: 8rem;
    width: 8rem;

    svg {
      height: 5rem;
      width: 5rem;
    }
  `}
`;

export const List = styled.ol`
  display: flex;
  flex-direction: column;
  max-width: 110rem;
  margin-top: 2rem;
  list-style: none;
  gap: 2.4rem;
`;

export const Wrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200rem;
  gap: 1.2rem;

  ${Heading} {
    max-width: 70rem;
  }

  @media (min-width: 680px) {
    ${List} {
      text-align: left;
      gap: 3.2rem;
    }
  }

  @media ${device.tablet} {
    h2,
    & > p {
      text-align: center;
    }

    ${Item} {
      &::before {
        width: 32rem;
        content: '';
        flex: 1;
      }
    }
  }

  @media ${device.laptop} {
    ${Heading} {
      max-width: 90rem;
    }

    ${List} {
      margin-top: 4rem;
    }
  }
`;
