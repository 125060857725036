import styled, { css } from 'styled-components';
import { device } from '../../../../../styles/media';
import { Section, Heading } from '../../Common';

export const List = styled.ol`
  display: flex;
  justify-content: center;
  max-width: 110rem;
  margin-top: 2rem;
  list-style: none;
  flex-wrap: wrap;
  gap: 1.6rem;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30rem;
    gap: 1rem;

    div {
      position relative;
      min-width: 1.5rem;

      &::before {
        content: "";
        display: block;
        background: ${theme.colors.pink};
        position: absolute;
        transform: translate(30%, 5%);
        border-radius: 0.4rem;
        height: 1.6rem;
        width: 1.6rem;
        bottom: 0;
        right: 0;
      }

      span {
        font-size: ${theme.font.sizes.xxmedium};
        font-weight: 500;
        position: relative;
        z-index: 1;
      }
    }

    & > span {
      width: 64%;
      margin: 0.8rem 0;
      background: ${theme.colors.pink};
      border-radius: 0.4rem;
      height: 0.2rem;
    }

    h3 {
      line-height: 1.3;
      font-size: ${theme.font.sizes.xmedium};
    }

    p {
      line-height: 1.5;
      font-size:${theme.font.sizes.small};
      font-weight: 300;
    }
  `}
`;

export const Wrapper = styled(Section)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 200rem;
    gap: 1.2rem;

    ${Heading} {
      max-width: 76rem;
    }

    @media (min-width: 680px) {
      ${List} {
        text-align: left;
        gap: 2.4rem;
      }

      ${ListItem} {
        align-items: flex-start;
      }
    }

    @media ${device.laptop} {
      ${List} {
        margin-top: 4rem;
      }

      ${Heading} {
        max-width: 96rem;
      }

      ${ListItem} {
        h3 {
          font-size: ${theme.font.sizes.xxmedium};
        }

        p {
          font-size: ${theme.font.sizes.medium};
        }
      }
    }
  `}
`;
