import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.black};
    padding-top: 9.2rem;
    min-height: 100vh;

    .title-pink {
      color: ${theme.colors.pink};
      font-weight: ${theme.font.bold};
    }

    .italic {
      font-style: italic;
    }
  `}
`;

export const Content = styled.div`
  flex: 1;
`;
