import Main from './Main';
import TextSection from './TextSection';
import TriggerAction from './TriggerAction';
import EmphasisText from './EmphasisText';
import Methodology from './Methodology';
import Benefits from './Benefits';
import BigNumbers from './BigNumbers';
import Trigger from './Trigger';
import Clients from './Clients';
import Contact from './Contact';
import CardSlider from './CardSlider';
import Newsletter from './Newsletter';
import SloganBenefits from './SloganBenefits';
import ExactaFlowTrigger from './ExactaFlowTrigger';
import About from './About';
import List from './List';

const Product = {
  Main,
  About,
  Trigger,
  BigNumbers,
  TextSection,
  TriggerAction,
  SloganBenefits,
  ExactaFlowTrigger,
  EmphasisText,
  Methodology,
  Newsletter,
  CardSlider,
  Benefits,
  Clients,
  Contact,
  List
};

export default Product;
