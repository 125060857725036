import React from 'react';

import { ContactForm, ContactFormProps } from '../../ContactForm';

import * as S from './styles';

function Contact(props: ContactFormProps) {
  return (
    <S.Wrapper as="div">
      <ContactForm {...props} />
    </S.Wrapper>
  );
}

export default Contact;
