import styled, { css } from 'styled-components';

import { Section, Heading as Title } from '../../Common';
import { ClientLogo } from '../../../Home/Clients/ClientLogo/styles';
import { device } from '../../../../../styles/media';

export const UpperHeading = styled.span`
  ${({ theme }) => css`
    font-size: 1.8rem;
    color: ${theme.colors.lightblue};
    font-weight: ${theme.font.bold};
    font-style: italic;
  `}
`;

export const Heading = styled(Title)`
  ${({ theme }) => css`
    span {
      color: ${theme.colors.green};
    }
  `}
`;

export const Wrapper = styled(Section)`
  ${({ theme }) => css`
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;

    ${ClientLogo} {
      gap: 2.4rem;
      margin: 4rem 0 0;
      max-width: 54rem;

      img {
        max-width: 8.2rem;
      }
    }

    @media ${device.tablet} {
      ${ClientLogo} {
        gap: 3.2rem;
        max-width: 74rem;
        margin: 4.8rem 0 0;

        img {
          max-width: 9rem;
        }
      }
    }

    @media ${device.laptop} {
      ${ClientLogo} {
        width: 100%;
        margin: 5.6rem 0 0;

        img {
          max-width: 10rem;
        }
      }
    }

    @media ${device.laptopL} {
      ${UpperHeading} {
        font-size: ${theme.font.sizes.xxmedium};
      }
    }
  `}
`;
