import React from 'react';

import { Heading, Text } from '../../Common';
import * as S from './styles';

export type ListProps = {
  heading: React.ReactNode;
  text: React.ReactNode;
  items?: {
    heading: string;
    text: string;
  }[];
};

function List({ heading, text, items }: ListProps) {
  return (
    <S.Wrapper>
      <Heading>{heading}</Heading>
      <Text>{text}</Text>

      {items?.length && (
        <S.List>
          {items.map((item, index) => (
            <S.ListItem key={index}>
              <div>
                <span>{index + 1}</span>
              </div>
              <span />

              <h3>{item.heading}</h3>
              <p>{item.text}</p>
            </S.ListItem>
          ))}
        </S.List>
      )}
    </S.Wrapper>
  );
}

export default List;
