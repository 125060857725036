import styled, { css } from 'styled-components';
import { device } from '../../../../../styles/media';
import { Section } from '../../Common';

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.lightgray};
    font-size: ${theme.font.sizes.xxmedium};
    margin: 1.6rem 0;
    font-weight: 500;

    @media ${device.tablet} {
      font-size: ${theme.font.sizes.xlarge};
      max-width: 100rem;
    }

    @media ${device.laptopL} {
      font-size: ${theme.heading.sizes.h5};
      max-width: 130rem;
    }
  `}
`;

export const Wrapper = styled(Section)`
  display: flex;
  justify-content: center;
  text-align: center;
`;
